'use client'

import { createContext, useContext } from 'react'
import { previewRequest } from '~/lib/client-only/preview-data'
import type { PreviewData } from '~/lib/types/preview.interface'

export interface PreviewDataContextInterface {
	enabled: boolean
	previewData?: PreviewData
	disable: ({ token }: { token: string }) => Promise<void>
	enable: ({ data, token }: { data: PreviewData; token: string }) => Promise<void>
	update: ({ data, token }: { data: Partial<PreviewData>; token: string }) => Promise<void>
}

export const defaultPreviewDataProvider: PreviewDataContextInterface = {
	enabled: false,
	disable: async () => undefined,
	enable: async () => undefined,
	update: async () => undefined,
}

export const PreviewDataContext = createContext(defaultPreviewDataProvider)

export function usePreviewData() {
	return useContext(PreviewDataContext)
}

export default function PreviewDataProvider({
	locale,
	previewData,
	children,
}: React.PropsWithChildren<{ locale: string; previewData?: PreviewData }>): React.ReactElement {
	const previewRequestWrapper = async (params: Omit<Parameters<typeof previewRequest>[0], 'locale'>) => {
		const resp = await previewRequest({ locale, ...params })
		if (resp.status - 200 < 100) {
			window.location.assign(window.location.href)
			// TODO: reload page ensuring all RSC are refreshed
		}
	}

	const disable = ({ token }: { token: string }) => previewRequestWrapper({ method: 'DELETE', token })
	const enable = ({ data, token }: { data: PreviewData; token: string }) =>
		previewRequestWrapper({ data, method: 'POST', token })
	const update = ({ data, token }: { data: Partial<PreviewData>; token: string }) =>
		previewRequestWrapper({ data, method: 'PATCH', token })

	const contextValue: PreviewDataContextInterface = {
		enabled: !!previewData,
		previewData,
		disable,
		enable,
		update,
	}

	return <PreviewDataContext.Provider value={contextValue}>{children}</PreviewDataContext.Provider>
}
