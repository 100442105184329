import type { PreviewData } from '../types/preview.interface'

export async function previewRequest({
	data,
	locale,
	method,
	token,
}: {
	data?: Partial<PreviewData>
	locale: string
	method: 'DELETE' | 'PATCH' | 'POST'
	token: string
}) {
	return fetch('/api/preview/', {
		body: JSON.stringify({ locale, ...(data ?? {}) }),
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		method,
	})
}
