'use client'

import { type NormalizedCacheObject } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'
import type { PropsWithChildren } from 'react'
import { useEffect, useCallback, useState, useMemo } from 'react'
import type { UserSessionInfo } from '~/lib/client-only/auth/types'
import { initApolloClient, standAloneApolloClient } from '~/lib/client-server/uacapi-client'
import { getDefaultLocale } from '~/lib/i18n/locale'
import { useSession } from '../UaSessionProvider/UaSessionProvider'
import type { GetUserIdQuery } from '~/graphql/generated/uacapi/type-document-node'
import { GetUserIdDocument } from '~/graphql/generated/uacapi/type-document-node'
import { getPublicConfig } from '~/lib/client-server/config'

const UNAUTH_HANDLER_TIMEOUT = getPublicConfig().uacapi.unauth_handler_timeout

export interface UaApolloProviderProps {
	// You can initialize the provider with a session if you want,
	//	but if you don't then it will go ahead and establish the
	//	session for you.
	optionalSession?: UserSessionInfo
	apolloStaticCache?: NormalizedCacheObject
	locale: string
}

export function useUaApolloClient(
	locale: string,
	optionalSession?: UserSessionInfo,
	apolloStaticCache?: NormalizedCacheObject,
) {
	const { refresh, setSession } = useSession()

	const [checkSession, setCheckSession] = useState(false)

	const unauthResponse = useCallback(() => setCheckSession(true), [])

	const checkSessionHandler = useCallback(() => {
		// Perform a single GetUserId call to determine if 401 was a fluke,
		// or was handled prior to a redraw. If it fails, refresh the user session
		standAloneApolloClient(locale)
			.query<GetUserIdQuery>({
				query: GetUserIdDocument,
				fetchPolicy: 'no-cache',
			})
			.catch(() => refresh())
	}, [locale, refresh])

	useEffect(() => {
		if (optionalSession) {
			setSession(optionalSession)
		}
	}, [optionalSession, setSession])

	useEffect(() => {
		if (checkSession) {
			setTimeout(() => setCheckSession(false), UNAUTH_HANDLER_TIMEOUT)
			checkSessionHandler()
		}
	}, [checkSession, checkSessionHandler])

	return useMemo(
		() => initApolloClient(locale ?? getDefaultLocale(), apolloStaticCache, unauthResponse),
		[apolloStaticCache, locale, unauthResponse],
	)
}

/**
 * The session initializer does the work of ensuring that a user gets signed
 * in as a guest user when there is no session information.  This has to be
 * done on each page refresh to ensure:
 *
 * 	1. If there's a bridged session from SFCC, that we properly load the state of that session into seabiscuit
 *  2. Even if there's no SFCC session, we need to initialize a guest session with UACAPI so that the cart will
 * 		will be preserved during the user's session.
 * @param param0
 * @returns
 */
export default function UaApolloProvider({
	optionalSession,
	children,
	locale,
	apolloStaticCache,
}: PropsWithChildren<UaApolloProviderProps>) {
	const client = useUaApolloClient(locale, optionalSession, apolloStaticCache)

	return <ApolloProvider client={client}>{children}</ApolloProvider>
}
